var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
      class: {
        "mb-2": _vm.position.includes("top"),
        "mt-2": _vm.position.includes("bottom")
      }
    },
    [
      _c("div", { staticClass: "p-4" }, [
        _c("div", { staticClass: "flex items-start" }, [
          _c(
            "div",
            { staticClass: "flex-shrink-0" },
            [
              _c(_vm.icon.component, {
                tag: "component",
                staticClass: "h-6 w-6",
                class: _vm.icon.colorClass
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-3 w-0 flex-1 pt-0.5" }, [
            _c("p", { staticClass: "text-sm font-semibold text-gray-900" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.title) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-1 text-sm text-gray-500" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.message) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4 flex-shrink-0 flex" }, [
            _c(
              "button",
              {
                staticClass:
                  "bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700",
                on: {
                  click: function($event) {
                    return _vm.$emit("remove")
                  }
                }
              },
              [
                _c("span", { staticClass: "sr-only" }, [_vm._v("Close")]),
                _vm._v(" "),
                _c("x-icon", { staticClass: "h-5 w-5" })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "absolute bg-white opacity-25 left-0 bottom-0 h-1",
        class: _vm.progressBarColorClass,
        style: { width: _vm.percentageElapsed + "%" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }