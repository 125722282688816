import "core-js/stable";
import "regenerator-runtime/runtime";
import 'proxy-polyfill';

require('./bootstrap');
import './create-nonce.js'


// Import modules...
import Vue from 'vue';
import PortalVue from 'portal-vue';
import {App as InertiaApp, plugin as InertiaPlugin} from '@inertiajs/inertia-vue';
import VueMyToasts from 'vue-my-toasts'
import Notification from '@/Components/Notification/Notification'
import '@/Components/GlobalComponents';
import axios from "axios";
import Zendesk from '@dansmaculotte/vue-zendesk'

require("@/Utils/ResizeHelper");

Vue.mixin({methods: {route}});
Vue.use(InertiaPlugin);
Vue.use(PortalVue);

Vue.use(VueMyToasts, {
    component: Notification,
    options: {
        position: 'top-right',
    }
});

const zendeskWidgetKey = document.head.querySelector('meta[name="zd-widget-key"]');
if (zendeskWidgetKey) {
    Vue.use(Zendesk, {
        key: zendeskWidgetKey.content,
        disabled: true,
        hideOnLoad: false,
        hideOnClose: true,
        settings: {
            analytics: false,
            webWidget: {
                offset: {vertical: '-5px'},
                position: {horizontal: 'right', vertical: 'bottom'},
                launcher: {
                    label: {
                        '*': 'Support'
                    }
                },
                contactForm: {
                    title: {
                        '*': 'Submit a Request'
                    },
                    ticketForms: [{
                        id: 14825844519191,
                        fields: [
                            {id: 'email', hint: {'*': ''}},
                            {id: 'subject', hint: {'*': ''}},
                            {id: 14825851970967, hint: {'*': ''}},
                            {id: 'description', hint: {'*': ''}}
                        ]
                    }],
                    attachments: true,
                }
            }
        }
    });
}

const app = document.getElementById('app');

new Vue({
    created() {
        // Add a response interceptor
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            const {status} = error.response ??= 500;
            let errorMessage = error.message ??= 'Internal Error';
            switch (status) {
                case 500:
                    this.$toasts.error('Internal Server Error');
                    break;
                case 422:
                    this.$toasts.error('Unprocessable Data Error');
                    break;
                default:
                    this.$toasts.error(errorMessage);
            }

            return Promise.reject(error);
        });
    },
    render: (h) =>
        h(InertiaApp, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: name => import( /* webpackChunkName: "[request]" */ `./Pages/${name}`).then(module => module.default),
            },
        }),
}).$mount(app);
