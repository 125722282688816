var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.showModal,
        title: _vm.title,
        "confirm-button-label": _vm.confirmButtonLabel,
        "cancel-button-label": _vm.cancelButtonLabel,
        "only-show-confirm-button": _vm.onlyShowConfirmButton,
        "btn-class": _vm.btnClass
      },
      on: {
        "update:show": function($event) {
          _vm.showModal = $event
        },
        confirm: _vm._confirm,
        cancel: _vm._cancel
      }
    },
    [
      _c("p", {
        staticClass: "text-sm text-gray-500",
        domProps: { innerHTML: _vm._s(_vm.message) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }