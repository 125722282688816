<template>
    <modal
        :show.sync="showModal"
        :title="title"
        :confirm-button-label="confirmButtonLabel"
        :cancel-button-label="cancelButtonLabel"
        :only-show-confirm-button="onlyShowConfirmButton"
        @confirm="_confirm"
        @cancel="_cancel" :btn-class="btnClass">
        <p class="text-sm text-gray-500" v-html="message"></p>
    </modal>
</template>

<script>
import Modal from "@/Components/Modals/Modal";

export default {
    name: "ConfirmationModal",
    components: {Modal},
    data() {
        return {
            showModal: false,
            title: null,
            message: null,
            onlyShowConfirmButton: false,
            confirmButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
            btnClass: 'btn-brand-danger',
            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.confirmButtonLabel = opts.confirmButtonLabel ? opts.confirmButtonLabel : 'Confirm';
            this.cancelButtonLabel = opts.cancelButtonLabel ? opts.cancelButtonLabel : 'Cancel';
            this.onlyShowConfirmButton = !!opts.onlyShowConfirmButton;
            if (opts.btnClass) {
                this.btnClass = opts.btnClass;
            }

            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.showModal = true;
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            this.showModal = false;
            this.resolvePromise(true)
        },
        _cancel() {
            this.showModal = false;
            this.resolvePromise(false)
        },
    }
}
</script>

<style scoped>

</style>
