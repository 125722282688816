<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div aria-labelledby="modal-title" aria-modal="true" class="modal pointer-events-none" role="dialog">
        <transition enter-active-class="ease-out duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0">
            <div v-if="show" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        </transition>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <transition enter-active-class="ease-out duration-300"
                            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                            leave-active-class="ease-in duration-200"
                            leave-class="opacity-100 translate-y-0 sm:scale-100"
                            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div v-if="show" class="pointer-events-auto relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6" :class="maxWidthClass">
                        <div>
                            <div class="mt-3 sm:mt-5">
                                <h3 class="text-lg leading-6 font-medium text-gray-900">
                                    {{ title }}
                                </h3>
                                <div class="mt-4">
                                    <slot></slot>
                                </div>
                            </div>
                        </div>
                        <div v-if="!hideActions" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            <div v-if="onlyShowCloseButton || onlyShowConfirmButton"></div>
                            <button @click="cancel"
                                    type="button"
                                    v-if="!onlyShowConfirmButton"
                                    class="w-full btn-md btn-white">
                                {{ cancelButtonLabel }}
                            </button>
                            <button
                                v-if="!onlyShowCloseButton"
                                @click="confirm"
                                type="button"
                                class="w-full btn-md" :class="[btnClass]">
                                {{ confirmButtonLabel }}
                            </button>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        title: String,
        confirmButtonLabel: {
            type: String,
            default: 'Confirm'
        },
        size: {
            type: String,
            default: 'sm'
        },
        cancelButtonLabel: {
            type: String,
            default: 'Cancel'
        },
        onlyShowCloseButton: {
            type: Boolean,
            default: false,
        },
        onlyShowConfirmButton: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        btnClass: {
            type: String,
            default: 'btn-brand'
        }
    },
    computed: {
        maxWidthClass() {
            switch (this.size) {
                case 'xs':
                    return 'sm:max-w-xs';
                case 'sm':
                    return 'sm:max-w-sm'
                case 'md':
                    return 'sm:max-w-md';
                case 'lg':
                    return 'sm:max-w-lg';
                case 'full':
                    return 'sm:container'
                default:
                    return '';
            }
        }
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.$el.click();
            }
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>
