<template>

    <div class="relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
         :class="{
        'mb-2': position.includes('top'),
        'mt-2': position.includes('bottom')
      }">

        <div class="p-4">
            <div class="flex items-start">
                <div class="flex-shrink-0">
                    <component class="h-6 w-6" :class="icon.colorClass"  :is="icon.component" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-semibold text-gray-900">
                        {{ title }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                        {{ message }}
                    </p>
                    <!--
                    <p>
                        <div class="mt-3 flex space-x-7">
                            <button class="bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Undo
                            </button>
                            <button class="bg-white rounded-md text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Dismiss
                            </button>
                        </div>
                    </p>
                    -->
                </div>

                <div class="ml-4 flex-shrink-0 flex">
                    <button @click="$emit('remove')"
                            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                        <span class="sr-only">Close</span>
                        <x-icon class="h-5 w-5" />
                    </button>
                </div>
            </div>
        </div>

        <div
            class="absolute bg-white opacity-25 left-0 bottom-0 h-1"
            :class="progressBarColorClass"
            :style="{ width: percentageElapsed + '%' }">
        </div>
    </div>

</template>

<script>

import ToastMixin from 'vue-my-toasts/src/mixins/ToastMixin'
import TimerMixin from 'vue-my-toasts/src/mixins/TimerMixin'
import { CheckCircleIcon, AlertCircleIcon, XCircleIcon, InfoIcon, XIcon } from 'vue-feather-icons'

export default {
    name: "Notification",
    mixins: [ToastMixin, TimerMixin],
    components: {
        CheckCircleIcon,
        AlertCircleIcon,
        XCircleIcon,
        InfoIcon,
        XIcon
    },
    props: {
        title: String,
        required: true
    },
    computed: {
        progressBarColorClass() {
            switch (this.type) {
                case 'success':
                    return 'bg-green-400'
                case 'warning':
                    return 'bg-yellow-400'
                case 'error':
                    return 'bg-red-400'
                case 'base':
                    return 'bg-blue-400'
                default:
                    return 'bg-blue-400'
            }
        },
        icon() {
            switch (this.type) {
                case 'success':
                    return {
                        component: 'check-circle-icon',
                        colorClass: 'text-green-400'
                    }
                case 'warning':
                    return {
                        component: 'alert-circle-icon',
                        colorClass: 'text-yellow-400'
                    }
                case 'error':
                    return {
                        component: 'x-circle-icon',
                        colorClass: 'text-red-400'
                    }
                case 'base':
                    return {
                        component: 'info-icon',
                        colorClass: 'text-blue-400'
                    }
                default:
                    return {
                        component: 'info-icon',
                        colorClass: 'text-blue-400'
                    }
            }
        }
    }
}
</script>

<style scoped>

</style>
