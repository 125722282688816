var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal pointer-events-none",
      attrs: {
        "aria-labelledby": "modal-title",
        "aria-modal": "true",
        role: "dialog"
      }
    },
    [
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "ease-out duration-300",
            "enter-class": "opacity-0",
            "enter-to-class": "opacity-100",
            "leave-active-class": "ease-in duration-200",
            "leave-class": "opacity-100",
            "leave-to-class": "opacity-0"
          }
        },
        [
          _vm.show
            ? _c("div", {
                staticClass:
                  "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fixed inset-0 z-10 overflow-y-auto" }, [
        _c(
          "div",
          {
            staticClass:
              "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          },
          [
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class": "ease-out duration-300",
                  "enter-class":
                    "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                  "enter-to-class": "opacity-100 translate-y-0 sm:scale-100",
                  "leave-active-class": "ease-in duration-200",
                  "leave-class": "opacity-100 translate-y-0 sm:scale-100",
                  "leave-to-class":
                    "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                }
              },
              [
                _vm.show
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "pointer-events-auto relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6",
                        class: _vm.maxWidthClass
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "mt-3 sm:mt-5" }, [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "text-lg leading-6 font-medium text-gray-900"
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.title) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-4" },
                              [_vm._t("default")],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        !_vm.hideActions
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                              },
                              [
                                _vm.onlyShowCloseButton ||
                                _vm.onlyShowConfirmButton
                                  ? _c("div")
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.onlyShowConfirmButton
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "w-full btn-md btn-white",
                                        attrs: { type: "button" },
                                        on: { click: _vm.cancel }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.cancelButtonLabel) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.onlyShowCloseButton
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "w-full btn-md",
                                        class: [_vm.btnClass],
                                        attrs: { type: "button" },
                                        on: { click: _vm.confirm }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.confirmButtonLabel) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }