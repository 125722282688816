import Vue from 'vue';
import EventBus from "@/EventBus";
import { ValidationProvider, extend, localize, localeChanged } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import fr from 'vee-validate/dist/locale/fr.json';
import JetButton from '@/Jetstream/Button'
import {Link as InertiaLink} from '@inertiajs/inertia-vue';
import ConfirmationModal from '@/Components/Modals/ConfirmationModal'

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize({en, fr});

Vue.component('validation-provider', ValidationProvider);
Vue.component('jet-button', JetButton);
Vue.component('inertia-link', InertiaLink);
Vue.component('confirmation-modal', ConfirmationModal);

EventBus.$on('locale-changed', (lang) => {
    localize(lang);
    localeChanged();
});
