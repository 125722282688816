import EventBus from "@/EventBus";
const debounceDelay = 350;
let cachedWidth = window.innerWidth;

window.addEventListener('resize', _.debounce(() => {
    let width = window.innerWidth;
    if (cachedWidth !== width) {
        cachedWidth = width;
        EventBus.$emit('window_resize');
    }
}, debounceDelay));

